export function getSuccesses(results) {
  let filteredList = results.filter((line) => {
    if (line.result) {
      return line;
    }
  });
  return filteredList;
}

export function getErrors(results) {
  // Pull out all the errors using filter.
  // Then push the errors into a separate array to return
  const returnArray = results
    .filter((line) => {
      if (line.error) {
        return true;
      }
    })
    .map((line) => {
      return {
        statusCode: line.error.statusCode,
        error: line.error.error.detail || line.error.error.message,
        body: JSON.stringify(line.error.options.body, null, 2),
        request: line.error.response ? line.error.response.request : null,
      };
    });
  return returnArray;
}
